<script setup lang="ts">
const route = useRoute()
const { $ROUTE } = useNuxtApp()
const { isModalOpen: isNavigationModalOpened } = useNavigation()
const { opened: isReservationPopupOpened, close } = useReservationPopup()

const eventBus = useEventBus<boolean>('guest-popup')
const [isGuestDataPopupOpened, setGuestDataPopupOpened] = useToggle(false)

const isContactUsPage = computed(() =>
  [
    $ROUTE.CONTACT_US,
    $ROUTE.CONTACT_US_SUCCESS,
    $ROUTE.CONTACT_US_ERROR,
  ].includes(route.path),
)

const isBookingCalendarPage = computed(
  () => $ROUTE.BOOKINGS_CALENDAR === route.path,
)

const unsubscribe = eventBus.on(setGuestDataPopupOpened)

useRouter().afterEach(() => {
  if (isReservationPopupOpened.value) close()
})

onBeforeUnmount(() => unsubscribe?.())
</script>

<template>
  <div
    v-if="$auth.loggedIn"
    class="relative flex min-h-screen flex-col"
    :class="{ 'bg-bgr md:bg-bgr-100': isContactUsPage }"
  >
    <OnDevices v-if="isBookingCalendarPage" rule=">=md">
      <LayoutHeaderLoggedIn />
    </OnDevices>
    <LayoutHeaderLoggedIn
      v-else
      :class="{ 'sticky top-0 z-10 md:static': isContactUsPage }"
    />

    <OnDevices rule=">=md">
      <NavigationBar />
    </OnDevices>

    <div class="flex-full-h">
      <slot />

      <OnDevices rule=">=md">
        <SupportCenterButtonDesktop
          class="safari-rule sticky bottom-8 right-8 z-1 mb-8 mr-8 flex justify-end"
          :class="{ '-mt-[76px]': !isContactUsPage || isBookingCalendarPage }"
        />
      </OnDevices>
    </div>

    <OnDevices v-if="isContactUsPage || isBookingCalendarPage" rule=">=md">
      <div>
        <BasicDivider class="bg-bgr-300" />

        <LayoutPreFooter />

        <LayoutFooter />
      </div>
    </OnDevices>

    <template v-else>
      <LayoutPreFooter />

      <LayoutFooter class="mb-[75px] shrink-0 md:mb-0" />
    </template>

    <OnDevices
      v-if="!isContactUsPage && !isBookingCalendarPage"
      rule="<md"
      show-class="flex"
    >
      <NavigationWrapper
        class="fixed inset-0 z-[11] flex"
        :class="isNavigationModalOpened ? 'flex-col' : 'flex-col-reverse'"
      />
    </OnDevices>

    <LazyLayoutGuestsDataPopup
      v-if="isGuestDataPopupOpened"
      @close="setGuestDataPopupOpened(false)"
    />
    <LazyReservationsPopup v-if="isReservationPopupOpened" />
  </div>
</template>

<style scoped>
.safari-rule {
  position: -webkit-sticky;
}
</style>
